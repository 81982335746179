import React, { useState } from "react";
import { X } from "lucide-react";
import { CartItem } from "../types";
import { CheckoutForm } from "./CheckoutForm";

interface CartModalProps {
  isOpen: boolean;
  onClose: () => void;
  items: CartItem[];
  onRemoveFromCart: (id: string) => void;
}

export function CartModal({
  isOpen,
  onClose,
  items,
  onRemoveFromCart,
}: CartModalProps) {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleSubmit = async () => {
    const orderData = { items };
    console.log("Submitting order:", orderData);

    try {
      const response = await fetch(
        "https://send.pageclip.co/IC3phwe62WpFxnGaPYk7A5khlJHzP56D/orders",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(orderData),
        }
      );

      console.log("Response received:", response);

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `HTTP error! status: ${response.status}, message: ${errorText}`
        );
      }

      // Check if the response is JSON
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const data = await response.json();
        console.log("Order submitted successfully", data);
        setSuccess("Order submitted successfully");
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (err) {
      console.error("Error submitting order:", err);
      setError(`Failed to submit order. Please try again. Error: `);
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="cart-modal">
      <button onClick={onClose}>
        <X />
      </button>
      <ul>
        {items.map((item) => (
          <li key={item.id}>
            {item.name} - {item.price}
            <button onClick={() => onRemoveFromCart(item.id)}>Remove</button>
          </li>
        ))}
      </ul>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
      <button onClick={handleSubmit}>Submit Order</button>
      <CheckoutForm
        items={items}
        total={items.reduce((sum, item) => sum + item.price, 0)}
        onClose={onClose}
      />
    </div>
  );
}
